import { fetchNovelLikedList } from '@/api/novel';
import { fetchNovelLastViewList } from '@/api/view';
import { GridBox } from '@/components';
import NovelItem from '@/components/NovelItem';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useUser } from '@/stores';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 60px;

  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }
`


export default function Page () {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const observerRef = useRef(null);

  useEffect(() => {
    const loadMoreNovels = async () => {
      const newList = await fetchNovelLastViewList();
      setList(prevList => [...prevList, ...newList]);
      // setTotalPage(totalPage);
    };

    loadMoreNovels();
  }, [page]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(([entry]) => {
  //     if (entry.isIntersecting && page < totalPage) {
  //       setPage(prevPage => prevPage + 1);
  //     }
  //   }, {
  //     rootMargin: '20px',
  //     threshold: 1.0
  //   });

  //   if (observerRef.current) observer.observe(observerRef.current);

  //   return () => {
  //     if (observerRef.current) observer.unobserve(observerRef.current);
  //   };
  // }, [page, totalPage]);

  useHideLayout('header', true);

  const { user } = useUser('liked');

  return (
    <Container>
      <TitleHeader title="나의 서재"/>

      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {list?.map((novel, key) => 
            <NovelItem {...novel} key={key} userAdultCheck={user?.adultVerification}/>
          )}
        </GridBox>

        {/* 이 부분에 observer를 트리거하는 div 추가 */}
        <div ref={observerRef} style={{ height: '1px' }} />
      </div>


      {list?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>조회한 작품이 아직 없습니다.</div>
          <div className='font-18 mt-20'>작품을 읽어보세요!</div>
        </div>
      }
    </Container>
  )
}