import { fetchOdiBuyHistory } from '@/api/coin';
import { ic_odi } from '@/assets';
import Banner from '@/components/Banner';
import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader';
import { formatDateYMD, formatDateYYMD, formatNumber } from '@/utils/format';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 70px 0;

  a {
    color: #bababa;
  }
  .active {
    color: #000000;
  }

  .white-box {
    width: 100%;
    height: 24px;
    background-color: #e7e6e7;
    margin-bottom: 20px;
  }

  .listItem {
    border-top: 1px solid #dfdfdf;
    padding: 12px 10px;
    .odiImage {
      width: 32px;
    }
  }
  
  .no-data {
    width: 100%;
    height: 140px;
  }
`


export default function Page () {
  useHideLayout('header', true);

  const [list, setList] = useState({});
  const [originList, setOriginList] = useState([]);

  // [TODO] 정렬 필요
  const [sortType, setSortType] = useState(false);


  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await fetchOdiBuyHistory();
    
    setOriginList(data);
    setList(groupByDate(data));
  }

  function groupByDate(dataArray) {
    const groupedData = dataArray.reduce((acc, item) => {
        // 날짜만 추출 (시간 정보 제거)
        const dateKey = item.created_date.split('-')[0]; // "2024-07-21"

        // 해당 날짜 키가 이미 존재하는 경우 기존 배열에 추가
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(item);

        return acc;
    }, {});

    return groupedData;
  }
  
  return (
    <Container>      
      <TitleHeader title="결제내역"/>

      <Banner place={'odi_pay'}/>
      
      <div className='text-center flex-center mt-32 px-32 mb-52'>
        <NavLink className={'flex-1 font-24 bold'} to='/charge/history/buy'>결제내역</NavLink>
        <NavLink className={'flex-1 font-24 bold'} to='/charge/history/use'>스타 사용 내역</NavLink>
      </div>

      

      {originList?.length > 0 &&  <div className='w-full flex'>
        <div className='flex-1'></div>

        <div className='flex-center px-20 py-10 mb-10'>
          <div className='font-12 flex-center medium'>
            <div className={!sortType ? 'c-primary' : 'c-878787'} onClick={() => setSortType(false)}>최신순</div>
            <div className='mx-2 c-878787 font-10'>|</div>
            <div className={sortType ? 'c-primary' : 'c-878787'}  onClick={() => setSortType(true)}>과거순</div>
          </div>
        </div>
      </div>}

      {Object.keys(list).map(date => (
        <div key={date}>
          <div className='medium px-12 mb-12'>{formatDateYMD(date)}</div>
          <div>
            {list[date].map((item, index) => (
              <div key={item.created_date} className='listItem flex align-center'>
                <img className='odiImage mr-12' src={ic_odi}/>

                <div className='flex-1'>
                  <div className='flex align-end mb-8'>
                    <div className='font-18 medium'>스타 {item.amount}개</div>
                    <div className='medium font-14 ml-4'>({formatNumber(Number(item.amount * 100))}원)</div>
                  </div>

                  <div className='c-grey100 font-12'>{formatDateYYMD(item.created_date)}</div>
                </div>
                {item.isRefund && <div className='medium font-14'>환불됨</div>}
              </div>

            ))}
          </div>

          <div className='white-box'></div>
        </div>
      ))}

      {originList?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>결제내역이 없습니다</div>
        </div>
      }
      
    </Container>
  )
}