import { fetchFileToUri } from '@/api/file';
import { updateUserNickname, updateUserPassword, updateUserProfileImage } from '@/api/user';
import { sample_profile } from '@/assets';
import { Button, LabelInput } from '@/components';
import LoadingCover from '@/components/Loading/cover';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useUser } from '@/stores';
import { formatImageUri } from '@/utils/format';
import { validatePassword } from '@/utils/validate';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'

const Container = styled.div`
  padding: 110px 30px 60px 30px;
`

const ProfileImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 40px;

  .change-button {
    position: absolute;
    bottom: -12px;
  }

  .profileImage {
    border-radius: 50%;
  }
`

const FileInput = styled.input`
  display: none;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export default function Page () {
  useHideLayout('header', true);
  
  const imgRef = useRef();

  const { user, refreshUserData } = useUser('notification-user');

  const [isLoading, setIsLoading] = useState(false);
  const [nickname, setNickname] = useState(user?.nickname || "");
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: ""
  });

  
  useEffect(() => {
    window.document.body.style.overflow = '';
  }, []);

  return (
    <Container>
      {isLoading && <LoadingCover/>}
      <TitleHeader title={"계정 설정"}/>

      <ProfileImage>
        <img className='profileImage' ref={imgRef} src={formatImageUri(`profile/${user?._id}/profile_image.png`)} onError={(e) => {
          e.target.src = sample_profile;
        }} width={142} height={142}/>
        {/* <FileInput
          id="profile-image"
          type="file"
          accept="image/*"
          onChange={async (e) => {
            const file = e.target.files[0];
            const foldername = `profile/${user._id}`;
            const filename = `profile_image`;
            try{
              await fetchFileToUri(foldername, filename, file);

              // 이미지 주소
              const fileUri = `profile/${user._id}/profile_image.png`;


              // 이미지 새로고침
              if(imgRef.current)
              imgRef.current.src = formatImageUri(`profile/${user._id}/profile_image.png?time=${new Date().getTime()}`);
              
              // 이미지 등록
              try {
                await updateUserProfileImage(fileUri).then(async () => {
                  await refreshUserData();
                });
              } catch(e) {
                console.log(e);
              }
            } catch(e) {
              alert("이미지 업로드에 실패했습니다.");
            } finally {
              e.target.value = null;
            }
          }}
        /> */}
        {/* <Button className="change-button" width={156} height={36} fontSize={15} onClick={() => {
          document.getElementById("profile-image")?.click();
        }}>프로필 사진 변경</Button> */}
      </ProfileImage>

      <FormContainer>
        <LabelInput label="닉네임" value={nickname} onChange={({currentTarget: { value }}) => setNickname(value)} />
        <Button width="100%" height={52} fontSize={16} isLoading={isLoading} onClick={async () => {
          if (isLoading) return;
          setIsLoading(true);
          try {
            if (nickname === "") {
              return alert("닉네임을 입력해주세요.");
            }

            await updateUserNickname(nickname);
            await refreshUserData();
          } catch(e) {
            alert("닉네임 변경에 실패했습니다.");
          } finally {
            setIsLoading(false);
          }
        }} >닉네임 변경</Button>
      </FormContainer>

      {user != null && (user.loginType == null || user.loginType === "local") && (
        <FormContainer style={{ marginTop: "40px" }}>
          <LabelInput label="현재 비밀번호 입력" type="password" value={passwords.current} onChange={({currentTarget: { value }}) => setPasswords({...passwords, current: value})} />
          <LabelInput label="새 비밀번호 입력" type="password" value={passwords.new} onChange={({currentTarget: { value }}) => setPasswords({...passwords, new: value})} />
          <LabelInput label="새 비밀번호 확인" type="password" value={passwords.confirm} onChange={({currentTarget: { value }}) => setPasswords({...passwords, confirm: value})} />
          <Button width="100%" height={52} fontSize={16} isLoading={isLoading} onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);

            try {
              if(passwords.new !== passwords.confirm) {
                return alert("새 비밀번호와 확인 비밀번호가 일치하지 않습니다.");
              }
              else if(!validatePassword(passwords.new))
                return alert('비밀번호는 8~20자, 대소문자, 숫자, 특수기호가 포함되어야 합니다.')

              await updateUserPassword(passwords.current, passwords.new);
              setPasswords({
                current: "",
                new: "",
                confirm: ""
              });
              refreshUserData();
            } catch(e) {
              alert("비밀번호 변경에 실패했습니다.");
            } finally {
              setIsLoading(false);
            }
          }}>비밀번호 변경</Button>
        </FormContainer>
      )}
    </Container>
  )
}
