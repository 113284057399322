import axios, { axiosAuth } from "@/lib/axios";


// 장르에 맞는 소설 리스트 가져오기
export const fetchNovelGenreList = async (genre, page) => {
  const { data } = await axios.get(`/novel/nlist/${page}/${genre}`);
  return data;
};




// 소설 회차 가져오기
export const fetchNovelDetailData = async (uuid) => {
  const accessToken = localStorage.getItem('user_access_token');
  if(accessToken) return await fetchNovelDetailDataWithAuth(uuid); // 로그인
  else return await fetchNovelDetailDataWithoutAuth(uuid); // 비로그인
}



// 소설 상세 데이터 가져오기 - 비로그인
export const fetchNovelDetailDataWithoutAuth = async (uuid) => {
  try {
    const { data } = await axios.get(`/novel/view/${uuid}`);
    return data;
  } catch (e) {
    console.log(e);
    if(e?.response?.status === 404) {
      alert('소설 내용 조회에 실패했습니다');
      return window.location.href = '/';
    }
    else if (e?.response?.status === 403) {
      alert('성인 인증이 필요한 콘텐츠입니다.');
      return window.history.go(-1);
    }
  }
}


// 소설 상세 데이터 가져오기 - 로그인
export const fetchNovelDetailDataWithAuth = async (uuid) => {
  try {
    const { data } = await axiosAuth.get(`/novel/authview/${uuid}`);
    return data;
  } catch (e) {
    if(e?.response?.status === 404) {
      alert('소설 내용 조회에 실패했습니다');      
      return window.location.href = '/';
    }
    else if (e?.response?.status === 403) {
      alert('성인 인증이 필요한 콘텐츠입니다.');
      return window.location.replace('/adultverification');
    }
  }
}

// 소설 회차 리스트 가져오기 - 비로그인
export const fetchNovelEpisodeList = async (uuid) => {
  const { data } = await axios.get(`/episode/list/${uuid}`);
  return data;
}

// 소설 회차 리스트 가져오기 - 로그인
export const fetchNovelEpisodeListWithUser = async (uuid) => {
  const { data } = await axiosAuth.get(`/episode/list/user/${uuid}`); 
  return data;
}


// 소설 회차 가져오기
export const fetchNovelEpisodeListWithAuthCheck = async (uuid) => {
  const accessToken = localStorage.getItem('user_access_token');
  if(accessToken) return await fetchNovelEpisodeListWithUser(uuid);
  else return await fetchNovelEpisodeList(uuid);
}



// 소설 회차 리스트 가져오기 - 비로그인
export const fetchNovelEpisodeData = async (uuid) => {
  const { data } = await axiosAuth.get(`/episode/detail/${uuid}`);
  return data;
}


// 좋아요한 작품 목록
export const fetchNovelLikedList = async (page) => {
  const { data } = await axiosAuth.get(`/like/list/${page}`);
  return data;
}

// 알람설정한 작품 목록
export const fetchNovelAlarmList = async (page) => {
  const { data } = await axiosAuth.get(`/alarm/list/${page}`);
  return data;
}