import styled from 'styled-components'
import Banner from '../Banner';
import { GridBox } from '..';
import NovelItem from '../NovelItem';
import NovelItemSmall from '../NovelItemSmall';
import { useHomeNovelList } from '@/stores/homeNovelList';


const Container = styled.div`

`


export default function MainRecommend ({ user }) {
  const { 
    novelPromotion0List,
    novelPromotion1List, 
    novelLastViewList, 
    novelExposurePromotion0List 
  } = useHomeNovelList();
  

  return (
    <Container>
      <Banner place={'main'}/>

      {/* 최근 본  작품 */}
      {novelLastViewList?.length > 0 && 
        <>
          <div className='pl-16 font-20 black mt-40'>
            <span>최근 본 </span>
            <span className='c-primary'>작품</span>
          </div>

          <div className='w-full novel-slider pt-16 pl-16'>
            {novelLastViewList?.map((item, key) => 
              <NovelItemSmall {...item} key={key} userAdultCheck={user?.adultVerification}/>
            )}
          </div>
        </>
      }



      
      {/* 스확시간 */}
      {novelPromotion1List?.length > 0 && 
        <>
          <div className='pl-16 font-20 black mt-40'>
            <span>스확시간 </span>
            <span className='c-primary'>작품</span>
          </div>

          <div className='w-full novel-slider pt-16 pl-16'>
            {novelPromotion1List?.map(({target_novel}, key) => 
              <NovelItemSmall 
                {...target_novel} 
                hasTimeFreeRent={true} 
                key={`odsi` + key}
                userAdultCheck={user?.adultVerification}
              />
            )}
          </div>
        </>
      }


      {/* 타임딜 */}
      {novelPromotion0List?.length > 0 && 
        <>
          <div className='pl-16 font-20 black mt-40'>
            <span>타임딜 </span>
            <span className='c-primary'>작품</span>
          </div>

          <div className='w-full novel-slider pt-16 pl-16'>
            {novelPromotion0List?.map(({target_novel}, key) => 
              <NovelItemSmall 
                {...target_novel} 
                hasTimedeal={true} 
                key={`odsi` + key}
                userAdultCheck={user?.adultVerification}
              />
            )}
          </div>
        </>
      }


      <>
        <div className='pl-16 font-20 black mt-40'>
          <span>당신을 위한 </span>
          <span className='c-primary'>추천</span>
          
          <div className='pr-16 w-full pt-16'>
            <GridBox col={3} gap={20}>
              {novelExposurePromotion0List?.map(({target_novel}, key) => 
                <NovelItem 
                  {...target_novel} 
                  key={key}
                  userAdultCheck={user?.adultVerification}
                />
              )}
            </GridBox>
          </div>
        </div>
      </>

    </Container>
  )
}