import { axiosAuth } from "@/lib/axios"

export const fetchNovelPromotionList = async (novel_id) => {
  const { data } = await axiosAuth.get(`/promotion/coin/novel/list/${novel_id}`);
  return data;
}

// 스확시간 프로모션 조회
export const fetchNovelListPromotion1 = async () => {
  const { data } = await axiosAuth.get(`/promotion/coin/novel/promotion1/list`);
  return data;
}

// 타임딜 프로모션 조회
export const fetchNovelListPromotion0 = async () => {
  const { data } = await axiosAuth.get(`/promotion/coin/novel/promotion0/list`);
  return data;
}


// 당신만을 위한 추천 조회
export const fetcExposurehNovelListPromotion0 = async () => {
  const { data } = await axiosAuth.get(`/promotion/exposure/novel/promotion0/list`);
  return data;
}




// 프로모션 생성 ( 재화 관련 )
export const fetchJoinNovelPromotionRent = async (novel_id, timeFreeRent) => {
  try {
    const { data } = await axiosAuth.post(`/promotion/user/coin`, {
      target_novel: novel_id,
      target_coin_promotion: timeFreeRent._id, // 적용 소설
      title: timeFreeRent?.title, // 비고, 설명 등
      promotionType: "Odi", // "Odi", "Exposure"
      promotionNumber: timeFreeRent.promotionNumber, // 적용된 프로모션 // "매열무", "타임딜" 등
      promotion_end_date: timeFreeRent.promotion_end // 종료일 - 프로모션 데이트
    });

    if(data?.response?.statusCode === 409) {
      alert('이미 적용중인 프로모션입니다.');
      return null;
    }

    console.log(data)

    return data;
  } catch (err) {
    console.log(err);
    return alert('프로모션 받기에 실패했습니다.')
  }
}

// 해당 스타 프로모션을 사용중인지
export const fetchUserUseOdiPromotionCheck = async (promotion_id) => {
  const { data } = await axiosAuth.get(`/promotion/user/check/coin/${promotion_id}`);
  return data;
}